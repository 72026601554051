import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/LearnAboutSystm.css';

import Footer from '../Footer/Footer.js';

const LearnAboutSystm = () => {
	
	return(
	  <main className="Products-container">
		
		<div className="Navbar-promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025.</p></a>
	    </div>
		
		<div className="Navbar-epson-subcontainer">
		  <a href="https://www.scw.com/digitech/" className="promo-sign-in" id="signup-button" aria-label="Sign up button"><p>LIMITED TIME OFFER: Exclusive Epson Document Management Bundle</p></a>
	    </div>
		
		<section className="Products-welcome-container">
		  <div className="Products-main-left">
		    <h2>Overwhelmed by Complex, < br/>Costly Business Technology?</h2>
		    <p>Value-priced Sys.tm simplifies the work of safely accessing and utilizing information.</p>
		
			<div className="Learn-button-container" i18n>

        	  <a
          		mat-stroked-button
          		href="https://www.digitechsystems.com/wp-content/uploads/2024/09/Automation-Data-Sheet.pdf" 
          		className="welcome-button Learn-button mdc-button mdc-button--outlined mat-mdc-outlined-button mat-unthemed mat-mdc-button-base cdk-focused cdk-mouse-focused"
          		aria-label="Start now button">Learn More
		      </a>
		
			  <a
          		mat-stroked-button
          		href="https://www.youtube.com/watch?v=-VM4giIRkOg" target="_blank" rel="noopener noreferrer"
          		className="welcome-button mdc-button mdc-button--outlined mat-mdc-outlined-button mat-unthemed mat-mdc-button-base cdk-focused cdk-mouse-focused"
          		aria-label="Start now button">Watch Demo Video
		      </a>
		
      		</div>
	
		  </div>
		
		  <div className="Products-main-right">
		  </div>
		</section>
		
		<a href="https://www.digitechsystems.com/wp-content/uploads/2024/09/Sys.tm_Launch_Press_Release.pdf" target="_blank" rel="noopener noreferrer" className="Learn-press-release">
			<h3>Introducing Sys.tm: The perfect balance of intelligent information management, value and functionality</h3>
		</a>
		
		<section className="Products-bigpicture-container">
		  
		  <div className="Products-bigpicture-intro-hidden">
		    <h3 className="HowMightSystmHelpYou-h3 learn-hidden-h3">Sys.tm: An Intelligent Information Management Platform</h3>
		  </div>
		
		  <div className="Products-bigpicture-itemcontainer">
		
		    <div className="Products-bigpicture-items Products-bigpicture-intro">
		      <h3 className="HowMightSystmHelpYou-h3">Sys.tm: <br />Bring Your Information to Life</h3>
		    </div>
		
		  	<div className="Products-bigpicture-items Products-bigpicture-a">
				<div className="Products-bigpicture-imgblock bigpicture-imgblock-a"></div>
				<div className="Products-bigpicture-text" >
		          <h4>80% of companies that implement new tech are 2.6 times more likely to accelerate their digital transformation projects</h4>
		        </div>
		    </div>
		
			<div className="Products-bigpicture-items Products-bigpicture-b">
		    	<div className="Products-bigpicture-imgblock bigpicture-imgblock-b"></div>
				<div className="Products-bigpicture-text" >
		        	<h4>91% said the cloud makes it easier to meet government compliance requirements</h4>
				</div>
			</div>
			<div className="Products-bigpicture-items Products-bigpicture-c">
				<div className="Products-bigpicture-imgblock bigpicture-imgblock-c"></div>
				<div className="Products-bigpicture-text" >
					<h4>94% of businesses claimed an improvement in security after switching to the cloud</h4>
				</div>
		    </div>
		  </div>
		</section>
		
		<section className="Products-industry-container">
		  <h4>What's your Solution?</h4> 
		 <div className="Products-industry-itemContainer">
		  
		  <div className="Products-industry-item">
		    <div className="Products-industry-iconContainer">
			  <div className="Products-bigpicture-imgblock bigpicture-imgblock-c"></div>
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/ap.png" alt="AP image" width="40" height="40" />
		    </div>
		    <h4>Accounts Payable</h4>
		  </div>
		
		  <div className="Products-industry-item">
		    <div className="Products-industry-iconContainer">
			  <div className="Products-bigpicture-imgblock bigpicture-imgblock-c"></div>
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/med.png" alt="Medical image" width="40" height="40" />
		    </div>
		    <h4>Healthcare</h4>
		  </div>
		
		  <div className="Products-industry-item">
		    <div className="Products-industry-iconContainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/book.png" alt="Book image" width="40" height="40" />
		    </div>
		    <h4>Education</h4>
		  </div>
		
		  <div className="Products-industry-item">
		    <div className="Products-industry-iconContainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/hr.png" alt="HR image" width="40" height="40" />
		    </div>
		    <h4>Human Resources</h4>
		  </div>
		
		  <div className="Products-industry-item">
		    <div className="Products-industry-iconContainer">
		      
		    </div>
		    <h4>Goverment</h4>
		  </div>
		
		 </div>
		
		</section>
		
		<div className="Products-benefits-maincontainer">
		  <h2 className="Products-benefits-mainh2">Do More than Just Manage Information</h2>
		  <p className="Products-benefits-mainp">Leverage automation and smart data extraction to bring life to information! Deliver data to where it’s needed to level-up decision making across your organization.</p>
		<section className="Products-benefits-container">
		
		<div className="Products-benefits-topComputer">
		    <img className="Products-benefits-image" src="https://www.digitechsystems.com/wp-content/uploads/2024/03/compter-systm1.png" alt="industry image" width="" height="" />
		  </div>
		
		  <div className="Products-benefits-center">
		    <img className="Products-benefits-image" src="https://www.digitechsystems.com/wp-content/uploads/2024/03/compter-systm1.png" alt="industry image" width="" height="" />
		  </div>
		
		  <div className="Products-benefits-right">
			  
			 <div className="Products-benefits-items product-benefit-top">
		      <div className="Products-benefits-img maninfo-imgblock-a"></div>
		      <div>
		        <h4>Windows File System Environment </h4>
		        <p>Simplify every document task by embedding content management within your familiar Windows environment.</p>
			  </div>
		    </div>
			  
		    <div className="Products-benefits-items benefits-right product-benefit-b">
		        <div className="Products-benefits-img maninfo-imgblock-b"></div>
				<div>
		      		<h4>Deliver Information Where It's Needed Now</h4>
		      		<p>Once ingested, data can be extracted from documents and delivered to where it’s needed for smart decision-making across your organization.</p>
	            </div>
		    </div>
	
	 	    <div className="Products-benefits-items benefits-right product-benefit-c">
		        <div className="Products-benefits-img maninfo-imgblock-c"></div>
				<div>
		          <h4>Safeguard Documents and Data with End-to-End Security</h4>
		          <p>Sys.tm administrators can control access to information by user or group, and even limit what each person can do with the information according to their job tasks.</p>
				</div>
		    </div>
	
		  </div>

		</section>
		
	</div>
	
	
	  <section className="Products-HK-container">
		<img className="Learn-HK-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/03/hk-cutpic-color.png" width="120" height="120" alt="Learning icon"/>  
		<div className="Products-HK-textcontainer">
		
		 <div className="Products-HK-item">	 
		  
		  <h5>“Sys.tm brings business information to life. Instead of languishing in boxes, critical data is securely managed and safely accessible to workers in a user-friendly, automated platform. Best of all, information doesn’t just sit waiting to be used. Rather, companies can extract data, automate tasks, comply with regulations, and much more within the Sys.tm environment. Sys.tm simplifies the ways we manage an ever-increasing amount of information.”</h5> 
	      </div>
		
	         <em><p>- HK Bain, CEO, Digitech Systems</p></em>
			 </div> 
			  
	  </section>
	
	
	     <section className="Ready-start-container">	 
		  <h2>Ready to get started?</h2>
		
		<div className="Ready-button-container">
		  <a className="text-decoration" href="https://www.sys.tm/signup" aria-label="Get started button">
		    <button className="welcome-button">Get Started!</button>
		  </a>
		
		  <a className="text-decoration" href="https://info.sys.tm/#/quick-start" aria-label="Get started button">
		    <button className="welcome-button">Get the Quick Start Guide!</button>
		  </a>
		</div>
		
		</section>
	
				  
		   <section className="Products-info-container">
		
		    <div className="Home-products-item Products-container-left">
		 
		     <div className="Home-leftbackground-container">
		
		      <div className="Home-products-lefttext">
		        <h3>Discover the Features that Make Sys.tm so Easy to Use</h3>
				<p>Download our Product Sheet and Pricing Guide to better understand the Sys.tm experience.</p>
	          </div>  
	
			  <div className="Home-products-buttonmain">
		
	            <div className="Home-products-buttonbox">
		
		      	  <div className="Home-products-buttoncontainer">
				    <a href="https://www.digitechsystems.com/wp-content/uploads/2024/09/Systm-Product-Sheet.pdf" target="_blank" aria-label="Product sheet button"><button className="welcome-button product-buttons-a">Product Sheet</button></a>
		          </div>
	
			      <div className="Home-products-buttoncontainer">
				    <a href="https://www.digitechsystems.com/wp-content/uploads/2024/02/Systm-Pricing-Sheet.pdf" target="_blank" aria-label="Pricing sheet button"><button className="welcome-button product-buttons-b">Pricing Guide</button></a>
			      </div>
	
			    </div>
	
		      </div>
	
			 </div>
	
		    </div>
	
			<div className="Home-products-item Products-container-right">
					<img className="Products-sheet-compimg" src="https://www.digitechsystems.com/wp-content/uploads/2024/09/features-computer-sized.png" width="" height="" alt="computer icon"/>
		    </div>
		  </section>  
		  
		  
		  	   	<section className="Home-contact-container">
		  <div className="Home-contact-items Contact-container-left">
		  </div>
	      <div className="Home-contact-items Contact-container-right">
			<div className="Contact-left-textblock">
		      <h2>How Can We Help?</h2>
			  <p>Sys.tm's advanced solutions are more efficient and save energy to do more meaningful work.</p>
			</div>
			<div className="Home-contact-iconcontainer">
		
				<div className="Home-contact-subcontainer fast-call">
		        	<div className="Home-contact-fast-call">
						<img className="Welcome-bigpicture-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/07/fast-call-wr.png" width="37" height="37" alt="fast call icon"/> 
		            </div>
					<h5>We’ll give you a call back within 1 business day.</h5>
				  </div>
															
				  <div className="Home-contact-subcontainer demo-call">
		        	<div className="Home-contact-fast-call">
						<img className="Welcome-bigpicture-img bpi-bottom" src="https://www.digitechsystems.com/wp-content/uploads/2024/07/live-demo-wr.png" width="43" height="37" alt="live demo icon" /> 
		            </div>
					<h5>Explore the platform live with our specialists.</h5>
				  </div>
		
		        <NavLink to="/contact" className="welcome-button contact-button">Contact</NavLink>
		
		    </div>		
		     
		  </div>
		</section>
		
		<Footer />
		
	  </main>
	
	)
}

export default LearnAboutSystm;