import React from 'react';
import '../styles/Posts.css';

import Footer from '../Footer/Footer.js';

const relatedArticles = [
	
	{
	   id: '2',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm Brings Intelligent Information Management to SMBs',
	   extrenal_link: './#/systm-product-sheet',
	   grabber_question: '',
	   excerpt: 'Many SMBs compromise on features or security when buying technology in order to save money. Are you among them? If so, our newest product, Sys.tm offers everything you need without forcing you to pay for premium tiers or licenses explains US Business News. Check it out.',
       downloads: '',
	   feature_image: 'Resources-usb'
	},
	{
	   id: '3',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Three Musts for a Successful Digital Transformation eBook',
       extrenal_link: 'https://info.sys.tm/#/digital-transformation',
	   grabber_question: 'Oversized documents a big pain?',
	   excerpt: 'Download the Three Musts for a Successful Digital Transformation eBook for a helpful guide to your organization’s digital transformation journey.',
       downloads: '',
	   feature_image: 'Resources-sys'
	}
	
]



const ResourcesMicroservicesDS = () => {
	 
	
	return(
		
	  <section className="Posts-main-container">
		
		<div className="Posts-promo-subcontainer">
			<a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		
		<div className="Posts-welcome-container">
			<h1>Microservices Data Sheet</h1>
		</div>
		
	  <div className="Posts-body-container">
		
		  <h2>Ready to smart-size your technology spend?</h2>
		  <p><b>Greenwood Village, CO – January 2, 2024 –</b>The way businesses consume and pay for technology is changing. With Digitech Systems' fully-customizable microservices solution, you can access the exact features you need, right when you need them. Enjoy unlimited flexibility, faster deployment, increased security, and even save time and money with Robotic Process Automation (RPA).</p>
		<br/>
		<div className="Posts-pricing-rowcontainer">
		  
		   
		      <div className="Posts-systm-microserviesDS"></div>
		
		</div>	
		
<br/>
		<div className="Posts-intro-veritcalcontainer">
			<a href="https://www.digitechsystems.com/wp-content/uploads/2023/11/Microservices-Data-Sheet.png"><button className="Posts-button">Download Now</button></a>
			<p>Download the Microservices Data Sheet to learn how Digitech Systems microservices and microtransactions-based billing benefit your organization. </p>
		</div>
<br/>
	  
	</div>
	
	<section className="Posts-related-section">
		<h3>Related Articles</h3>
		<div className="Posts-related-container">
			
		{relatedArticles.map((article) => {
		
			return (
			<a className="Posts-article-linkbutton" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				<div className="Posts-related-item" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				  
				  <div className="Posts-article-title">
					  <h2>{article.title}</h2>
				
				
				    <div className="Posts-article-textcontainer">
				  	  <p className="Posts-article-grebber"><b>{article.grabber_question}</b></p>
				      <p className="Posts-article-excerpt">{article.excerpt}</p>
					</div>
					
				  </div>
				</div>	
				</a>
			)
	})}
	  
	  </div>
		
	</section>
			
</section>
	)
	
}

export default ResourcesMicroservicesDS;