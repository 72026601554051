import React from 'react';
import Footer from '../Footer/Footer.js';

import '../styles/BecomeaSystmReseller.css';

const BecomeaSystmReseller = () => {
	
	return(
	  <section className="Reseller-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up for free button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025.</p></a>
	    </div>
		
		<div className="Reseller-header-container">
		  <div class="HowMightSystmHelpYou-Headerbanner-textcontainer">
		    <h1>Sys.tm Reseller Program</h1>
			<p>Profit from offering the latest in information management technology.</p>
		   </div>
		</div>
		
		<a href="https://www.digitechsystems.com/wp-content/uploads/2024/09/Sys.tm_Launch_Press_Release.pdf" target="_blank" rel="noopener noreferrer" className="Learn-press-release">
			<h3>Introducing Sys.tm: The perfect balance of intelligent information management, value and functionality</h3>
		</a>

		<section className="Reseller-body-main">
		
		<div className="Reseller-body">
		
		  <div className="Reseller-body-subcontainer">
		
		      <p>The Sys.tm reseller program is a business opportunity that offers an easy source of recurring revenue. Provide businesses of any size with the latest in intelligent document and data management and task automation delivered through simple-to-use Sys.tm, the cloud information management services platform from industry pioneer Digitech Systems. Help your customers achieve their goals and solve business problems.</p> 
		
		      <h5>As a Sys.tm reseller, you will:</h5>
		      <ul className="Reseller-body-list">
		        <li>Get started with no start-up costs</li>
		        <li>Earn money for every account signup</li>
		        <li>Sell to a wide customer base with the industry’s best support</li>
		      </ul>
		
				<p>Sys.tm resellers receive unlimited access to Digitech Systems’ legendary technical support team where calls are answered within seconds by a US-based expert, and you will also have access to the interactive MyDSI online reseller and customer support portal for 24/7 resources, including free sales and marketing materials.</p>
		
		  </div>
		
		  <div className="Reseller-trust-container">
		  	<div className="Reseller-trust-left">
		    	<h3>Partner With A <br />Trusted Industry Leader</h3>
		  	</div>
		  	<div className="Reseller-trust-right">
		    	<p>Partnering with Digitech Systems means teaming with an industry pioneer with more than 25 years of success at helping resellers drive revenue growth by meeting organizations’ need for easy-to-use, cost-effective digitization, records management and task automation services.</p>
		  	</div>
		  </div>
		  
		
		</div>
		
		<div className="Reseller-icons-benefits" >
		
		  <div className="Reseller-icons-items reseller-container-a" >
		    <div className="Reseller-icons-img reseller-icon-a"></div>
			  <div className="Reseller-icons-textbox">
		    	<h5>Add Revenue to Every Sale</h5>
		    	<p>One-time and recurring revenue options available</p>
			  </div>
			</div>
		
		  <div className="Reseller-icons-items reseller-container-b" >
			<div className="Reseller-icons-img reseller-icon-b"></div>
			<div className="Reseller-icons-textbox">
		      <h5>Close Deals Faster</h5>
		      <p>Sign up customers online … and let us do the rest</p>
		    </div>
		  </div>
		
		  <div className="Reseller-icons-items reseller-container-c" >
			<div className="Reseller-icons-img reseller-icon-c"></div>
			<div className="Reseller-icons-textbox">
		      <h5>Target Any Size Business</h5>
		      <p>Offer flexible services <br />and prices</p>
			</div>
		  </div>
		
		</div>
		
		</section>

			 <div className="Reseller-form-container">
				
		        <div className="Reseller-form-items Reseller-form-title">
				  <div className="Reseller-form-titlebox">
		          	<h2>Ready to Start Selling?</h2>
			      	
					</div>
              	</div>
	
		      	<div class="Reseller-form-items form-wrapper">
		
		      	<form className="Sf-form-container">
				  <h4 className="Sf-form-h4">Fill out the form to let us know of your interest. <br />A client development representative will reach out.</h4>
				  
					<iframe className="Reseller-iframe-container" id="" title="" width="600" height="" scrolling="no" src="https://go.digitechsystems.com/Systm-Program_systm-reseller-landing-page.html" title="Marketo Form"></iframe>
		
				  <section className="Reseller-mobile-iframe">
		          	
		          </section>
		
              	</form>

              	</div>
		      
		
		
		    </div>
		
		 <Footer />
		
	  </section>
	
	)
}

export default BecomeaSystmReseller;