import { Routes, Route } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './App.css';
import LearnAboutSystm from './pages/LearnAboutSystm.js';
import Home from './pages/Home.js';
import Contact from './pages/Contact.js';
import Pricing from './pages/Pricing.js';
import Navbar from './Navbar/Navbar.js';
import Footer from './Footer/Footer.js';
import BecomeaSystmReseller from './pages/BecomeaSystmReseller.js';
import BuyersChecklist from './pages/BuyersChecklist.js';
import PromoMicroservices from './pages/PromoMicroservices.js';
import QuickStartGuide from './pages/QuickStartGuide.js';
import DigitalTransformation from './pages/DigitalTransformationeBook.js';

import Privacypolicy from './pages/PrivacyPolicy.js';
import Accessibility from './pages/Accessibility.js';
import HowMightSystmHelpYou from './pages/HowMightSystmHelpYou.js';
import ThanksExploreMore from './pages/ThanksExploreMore.js';
import Resources from './pages/Resources.js';
import Automation from './pages/Automation.js';

import LaunchesNextGen from './Posts/LaunchesNextGen.js';
import ResourcesProductSheet from './Posts/ResourcesProductSheet.js';
import ResourcesPricingSheet from './Posts/ResourcesPricingSheet.js';
import ResourcesMicroservicesDS from './Posts/ResourcesMicroservicesDS.js';
import ResourcesMicroservicesDefinitions from './Posts/ResourcesMicroservicesDefinitions.js';
import ResourcesArchitectureVideo from './Posts/ResourcesArchitectureVideo.js';
import CriticalTrends2025 from './Posts/CriticalTrends2025.js';
import ModuleAutomation from './Posts/ModuleAutomation.js';
import SouthernComputer from './Posts/SouthernComputer.js';



import { initGA } from "./ga-utils";

import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";

import ScrollToTop from './ScrollToTop/ScrollToTop.js';

function App() {
	
	const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };
	const handleDeclineCookie = () => {
      //remove google analytics cookies
      Cookies.remove("_ga");
      Cookies.remove("_gat");
      Cookies.remove("_gid");
    };
	
	useEffect(() => {
      const isConsent = getCookieConsentValue();
      if (isConsent === "true") {
        handleAcceptCookie();
      }
    }, []);
	
  const [message, setMessage] = useState('');
  useEffect(() => {
    fetch('/')
      .then((res) => res.text())
      .then((data) => setMessage(data))
      .catch((err) => console.log(err));
  }, []);
	
	
  return (
        <main className="App">
	    <ScrollToTop />
	  	<Navbar />
	  
	      <div className="Padding-block"></div>
	  
	  <CookieConsent
	    style={{ background: "rgba( 6, 35, 71, .9 )", padding: "20px 0", color: "#ffffff" }}
	    buttonStyle={{ padding: "12px 50px", color: "#062347", fontSize: "1em", background: "#ffffff" }}
	    declineButtonStyle={{ padding: "12px 50px", color: "#ffffff", fontSize: "1em", background: "none" }}
	    enableDeclineButton
	    flipButtons
	    onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
	    buttonClasses="myHoveringButton"
	    declineButtonClasses="myHoveringDeclineButton"
	    expires={150} 
	    contentClasses="mainButtonStyle"
	  	buttonWrapperClasses="mainButtonWrapper"
	    buttonText="Accept All"  
	  	declineButtonText="Decline All"
	  
	  >
	  
	    <h3 className="Cookie-h3">We Value Your Privacy</h3>
	    <p className="Cookie-p">This website uses cookies to enhance the user experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.</p>
	  
      </CookieConsent>
	  
	  <Routes>
	    <Route path="/" element={<LearnAboutSystm />}/>
	    <Route path="/learn-about-systm" element={<LearnAboutSystm />}/>
	  
	    <Route path="/contact" element={<Contact />}/>
	    <Route path="/become-a-systm-reseller" element={<BecomeaSystmReseller />}/>
	    <Route path="/buyers-checklist" element={<BuyersChecklist />}/>
	    <Route path="/privacy" element={<Privacypolicy />}/>
	    <Route path="/accessibility" element={<Accessibility />}/>
	    <Route path="/pricing" element={<Pricing />}/>
	    <Route path="/resources" element={<Resources />}/>
	    <Route path="/systm-microservices" element={<PromoMicroservices />}/>
	    <Route path="/quick-start" element={<QuickStartGuide />}/>
	    <Route path="/digital-transformation" element={<DigitalTransformation />}/>
	    <Route path="/how-might-systm-help-you" element={<HowMightSystmHelpYou />}/>
	    <Route path="/thanks-explore-more" element={<ThanksExploreMore />}/>
	    <Route path="/automation" element={<Automation />}/>
	  
	    <Route path="/digitech-systems-launches-next-gen-information-management" element={<LaunchesNextGen />}/>
	    <Route path="/product-sheet" element={<ResourcesProductSheet />}/>
	    <Route path="/pricing-sheet" element={<ResourcesPricingSheet />}/>
	    <Route path="/microservices-data-sheet" element={<ResourcesMicroservicesDS />}/>
	    <Route path="/microservices-definitions" element={<ResourcesMicroservicesDefinitions />}/>
	    <Route path="/architecture-video" element={<ResourcesArchitectureVideo />}/>
	    <Route path="/criticalTrends2025" element={<CriticalTrends2025 />}/>
	    <Route path="/module-automation-revolution" element={<ModuleAutomation />}/>
	    <Route path="/southern-computer" element={<SouthernComputer />}/>
	  
	  
      </Routes>
	  
        </main>
	  
	  
  );
}
export default App;
