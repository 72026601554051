import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ThanksExploreMore.css';

import Footer from '../Footer/Footer.js';

const ThanksExploreMore = () => {
	
	return(
	  <section className="ThanksExploreMore-container">
		
		<div className="Navbar-promo-subcontainer">
			<a href="https://www.sys.tm/signup" className="sign-in-nav" ><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025.</p></a>
	    </div>
		
		<div className="ThanksExploreMore-welcome-container">
		  <div className="ThanksExploreMore-welcome-subcontainer ThanksExploreMore-welcome-leftcontainer">
		    <h2>Thank you for your interest in our content.</h2>
		    <p>You should see an email shortly that includes a link to download your document.</p>
		  </div>
		  <div className="ThanksExploreMore-welcome-subcontainer ThanksExploreMore-welcome-rightcontainer">
		    <img className="ThanksExploreMore-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/11/laptop-systm-shadow.png" alt="ebook image"/>
		  </div>
		</div>
		
		<section className="ThanksExploreMore-channels-container">
		
		  <h4 className="ThanksExploreMore-meantime-title">In the meantime, please feel free to explore more of our Sys.tm website to learn more about how Sys.tm information management can simplify and streamline your business information and automate tasks to save you time and money.</h4>
		
		  <div className="ThanksExploreMore-channels-itemscontainer">
		
		
		       	  <div className="ThanksExploreMore-channels-item">

					  <div className="thanks-text-container">
						<div className="Thanks-text-learnimg"></div>
		        	  	<h5 className="thanks-text-h5">Learn About Sys.tm</h5>
					  	<p className="thanks-text-p">Sys.tm provides all the power of the world’s best information management and task automation in an easy-to-setup, easy-to-use product that offers you the ability to customize available features and pay only for what you actually use.</p>
					  </div>
					  <Link to="/learn-about-systm" className="welcome-button thanks-button"><p>Learn More</p></Link>
		
		          </div>
		
		
		       	  <div className="ThanksExploreMore-channels-item">
					  <div className="thanks-text-container">
						<div className="Thanks-text-howimg"></div>
		        	  	<h5 className="thanks-text-h5">How Might Sys.tm Help You?</h5>
					  	<p className="thanks-text-p">Don’t take our word for how great Sys.tm is. Check out Sys.tm customer stories, media quotes, awards, and more.</p>
					  </div>
					  <Link to="/how-might-systm-help-you" className="welcome-button thanks-button"><p>Learn More</p></Link>
		          </div>
		
		
		       	  <div className="ThanksExploreMore-channels-item Thanks-contact-container">
		
					  <div className="thanks-item-textbox">
		        	  	<h5 className="thanks-text-h5">Contact Us</h5>
					  	<p className="thanks-text-p">Sys.tm provides all the power of the world’s best information management and task automation in an easy-to-setup , easy-to-use product that offers you the ability to customize available features and pay only for what you actually use.</p>
						<h4 className="thanks-text-h5 sales-info">Sales Information:</h4>
						<h5 className="thanks-sales-contact">Toll-Free:</h5>
						<p>+ 866.374.3569</p>
						<h5 className="thanks-sales-contact">International:</h5>
						<p>+1.303.493.6900</p>
						<h5 className="thanks-sales-contact">Email:</h5>
						<p>Sales@Sys.tm</p>
					  </div>
		
					  <Link to="/contact" className="welcome-button thanks-contact-button">Contact</Link>
		
		          </div>
		

		  </div>
		
		
		</section>
		
		<Footer />
		
	  </section>
	
	)
}

export default ThanksExploreMore;