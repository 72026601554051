import React from 'react';
import '../styles/Posts.css';

import Footer from '../Footer/Footer.js';

const relatedArticles = [
	
	{
	   id: '2',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm Pricing Sheet',
	   extrenal_link: './#/pricing-sheet',
	   grabber_question: 'Bring Information to Life',
	   excerpt: 'How much time and money would better information management save your business? Sys.tm provides all the power of the world’s best information management and task automation in an easy-to- setup, easy-to-use product that offers you the ability to customize available features and pay only for what you actually use.',
       downloads: '',
	   feature_image: 'Resources-usb'
	},
	{
	   id: '3',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Three Musts for a Successful Digital Transformation eBook',
       extrenal_link: 'https://info.sys.tm/#/digital-transformation',
	   grabber_question: 'Oversized documents a big pain?',
	   excerpt: 'Download the Three Musts for a Successful Digital Transformation eBook for a helpful guide to your organization’s digital transformation journey.',
       downloads: '',
	   feature_image: 'Resources-sys'
	}
	
]



const ResourcesMicroservicesDS = () => {
	 
	
	return(
		
	  <section className="Posts-main-container">
		
		<div className="Posts-promo-subcontainer">
			<a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		
		<div className="Posts-welcome-container">
			<h1>Microservices and Microtransactions-based Billing Definitions</h1>
		</div>
		
	  <div className="Posts-body-container">
		
		  <h2>Emerging trend in tech comes with evolving terminology</h2>
		  <p><b>Greenwood Village, CO – January 2, 2024 –</b>Typical of new technology, the terminology surrounding microservices and microtransactions-based billing is still a work in progress.  Microtransactions-based billing refers to paying only for the amount of services actually used. With Digitech Systems robotic process automation (RPA), as an example, it is a microservice that costs only pennies per hour for the time the electronic bots are performing common back-office tasks.</p>
		<br/>
		<div className="Posts-definitions-reverserowcontainer">
		  
		   <p>To help you better understand these emerging trends in technology purchasing and development, we’ve put together a few definitions, related terms and other information. We’ll update this list as more terms emerge.</p>
		      <div className="Posts-systm-def-cloud"></div>
		
		</div>	
		
		<a href="https://www.digitechsystems.com/features/microservices-microtransactions/"><h4>Get a free copy of our data sheet on microservices and microtransactions-based billing.</h4></a>
		
<br/>
		<div className="Posts-intro-veritcalcontainer">
			<h3><b>Definitions</b></h3>
		<div>
		
		
	<section className="Posts-definitions-rowcontainer">
		
	  <div className="Posts-definitions-colcontainer">	
		<div>
       		<p><b>Microservices:</b> A style of software architecture in which developers build and test components of code that can be repurposed across an array of products.</p>
		    <pre className="Posts-definitions-pre"><p><b>Related terms:</b> Containerization, composable technology, microservices architecture.</p></pre>
		</div>
		
		<div>
           <p><b>Benefit to end user:</b>  Instead of purchasing a large software package that includes many features that might go unused, you save by choosing only the specific individual cloud information management services you need. You save further by paying only for the amount those services are used. No more wasted time and money on bloated, aged software packages.</p>
		</div>
	  </div>	
		
		<div className="Posts-definitions-hands"></div>
		
		</section>
		
	<div>
       <p><b>Benefit for developers:</b>  Allows developers to produce small pieces or chunks of code that can be repurposed, reused and recombined in any combination to produce new products. Developers can produce products more quickly with higher quality. Individual services can be updated and upgraded independent of one another.</p>
	</div>
		
		
	<section className="Posts-definitions-rowcontainer">	
	<div className="Posts-definitions-colcontainer">
       <p><b>Microtransactions-based billing:</b> Users are billed only for the services they accessed and the amount they use each service during the billing period. Technology advances enable microservices providers to keep precise tabs on the specific technologies used and then calculate payments accordingly.</p>
       <pre className="Posts-definitions-pre"><p><b>Related terms:</b> Micropayment.</p></pre>
	</div>
		<div className="Posts-definitions-greenbutton"></div>
		</section>
		
		
	<div>
       <p><b>Real world example:</b>  Digitech Systems’ robotic process automation (RPA) costs only pennies per hour for the time electronic bots are actually performing routine office tasks such as transferring documents and data.</p>
	</div>
		
	<div>
       <p><b>Container:</b>  A container is a bundle of application code and all the related configuration files, libraries, and dependencies required for the application to run.</p>
	</div>
	
	<div>
       <p><b>Interoperability:</b>  The integration of  technology, applications and/or IT systems to improve transparency, agility, scalability, productivity and customer experience.</p>
	</div>
		
	<div>
       <p><b>Modular:</b> The breakup of complex systems into small, reusable building blocks such as microservices and APIs that can be easily assembled and reassembled to create new applications.</p>
	</div>
		
	<div>
       <p><b>Platform:</b>  A hosted service accessible through the cloud that provides all the hardware and software tools needed to run cloud software applications, including microservices.</p>
	</div>
		<hr className="Posts-hr"/>
		<p><em className="Posts-sig">Digitech Systems Sales and Marketing</em></p>
		
		
		
        </div>
		
		
		
		
		</div>
<br/>
	  
	</div>
	
	<section className="Posts-related-section">
		<h3>Related Articles</h3>
		<div className="Posts-related-container">
			
		{relatedArticles.map((article) => {
		
			return (
			<a className="Posts-article-linkbutton" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				<div className="Posts-related-item" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				  
				  <div className="Posts-article-title">
					  <h2>{article.title}</h2>
				
				
				    <div className="Posts-article-textcontainer">
				  	  <p className="Posts-article-grebber"><b>{article.grabber_question}</b></p>
				      <p className="Posts-article-excerpt">{article.excerpt}</p>
					</div>
					
				  </div>
				</div>	
				</a>
			)
	})}
	  
	  </div>
		
	</section>
			
</section>
	)
	
}

export default ResourcesMicroservicesDS;