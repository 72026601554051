import React from 'react';
import { NavLink } from 'react-router-dom';
import { useRef } from 'react';


import '../styles/Automation.css';
import Footer from '../Footer/Footer.js';


const Automation = () => {
	
	
	const aboutRef = useRef(null);
	const matterRef = useRef(null);
	const keyRef = useRef(null);
	const strategicRef = useRef(null);
	
	const handleAboutClick = () =>{
		console.log('About Click')
		aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	
	const handleMattersClick = () =>{
		console.log('Matters Click')
		matterRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	
	const handleKeyClick = () =>{
		console.log('Key Click')
		console.log(keyRef.current)
		keyRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	
	const handleStrategicClick = () =>{
		console.log('Strategic Click')
		strategicRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	
	
	
	return (
		
	  <section className="Pillar-body-container">
		<section className="Pillar-main-container">
		
		  <div className="Pillar-table-subcontainer">
		    <h3>Table of Contents</h3>
			<div className="Pillar-table-sidemenu">
		      <button onClick={handleAboutClick}>Go to About</button>
			  <button onClick={handleMattersClick}>Why Automation Matters</button>
		      <button onClick={handleMattersClick}>Key Click</button>
		      <button onClick={handleMattersClick}>Strategic Click</button>
		    </div>
		

		  </div>
		
		<div className="Pillar-main-subcontainer">
		
		{/* introduction section */}
		
		 <section className="Pillar-about-subcontainer" ref={aboutRef} id="about">
		
		  <div className="Pillar-item-container" >
		   <h1>What is Automation?</h1>
		   <p>Definition: Process automation is the use of technology to perform recurring tasks or workflows, reducing manual effort.</p>
		   <ul>
		     <li className="Pillar-list" >Workflow Automation: Technology that automates a series of tasks to improve the flow and efficiency of entire processes.</li>
		     <li className="Pillar-list" >Robotic Process Automation (RPA): Technology that automates single, repetitive tasks by imitating human actions to simplify work.</li>
		   </ul>
			<p><strong>Evolution:</strong> Automation has grown from simple mechanical processes to advanced AI-driven systems that can automate both routine and complex tasks.</p>
			<p><strong>Application Across Industries:</strong> Automation is now present in sectors like healthcare, finance, and manufacturing, where repetitive tasks are abundant.</p>
	
		  </div>
		
		  <div className="Pillar-item-container">
		    <h3>Importance in Modern Business Operations</h3>
		    <p><strong>Competitiveness:</strong> In a rapidly evolving digital landscape, automation is key to staying competitive by improving productivity and lowering operational costs.</p>
			<p><strong>Increased Demand:</strong> Demand for automation from business teams has significantly increased, reflecting its growing role in streamlining operations and meeting business needs.</p>
		  </div>
	
	      <div className="Pillar-item-container">
		   <h3>Overview of What the Reader Will Learn</h3>
		   <p><strong>Key Concepts:</strong> You will learn about the benefits of automation, the technologies driving it, and its key applications across industries.</p>
		   <p><strong>Emerging Trends:</strong> The page will explore hyper-automation, AI, and the rise of low-code platforms, providing insights into the future of automation.</p>
		   <p><strong>Real-World Examples:</strong> Case studies and practical examples will illustrate how businesses have successfully implemented automation to enhance operations.</p>
		  </div>
	
	      <div className="Pillar-item-container">
		   <h3>Overview of What the Reader Will Learn</h3>
		   <p><strong>Key Concepts:</strong> You will learn about the benefits of automation, the technologies driving it, and its key applications across industries.</p>
		   <p><strong>Emerging Trends:</strong> The page will explore hyper-automation, AI, and the rise of low-code platforms, providing insights into the future of automation.</p>
		   <p><strong>Real-World Examples:</strong> Case studies and practical examples will illustrate how businesses have successfully implemented automation to enhance operations.</p>
		  </div>
	
	    </section>
	{/* end of introduction section */}
	
	{/* why is matters section */}
	
	    <section className="Pillar-matters-subcontainer" ref={matterRef} id="matter">
			
		  <div className="Pillar-item-container">
			<h1>Why Automation Matters</h1>
		  </div>
	
		  <div className="Pillar-matters-itemscontainer">
			  
			
	        <div className="Pillar-item-container">
		   		<h3>Efficiency Improvements and Cost Reduction:</h3>
		   		<p><strong>Broad Operational Gains:</strong> Automation helps businesses achieve faster processes, reduce operational costs, and improve overall accuracy. These benefits are realized across various functions, from finance to customer service.</p>
		   		<p><strong>Resource Optimization:</strong> Automating repetitive tasks like data entry and invoicing leads to better use of resources and significant cost savings.</p>
		    </div>  
	
		    <div className="Pillar-item-container">
		   		<h3>Enhanced Productivity and Reduced Errors:</h3>
		   		<p>Boost in Output: Employees can shift their focus from mundane, repetitive tasks to higher-value work, leading to improved productivity.</p>
		   		<p><strong>Emerging Trends:</strong> The page will explore hyper-automation, AI, and the rise of low-code platforms, providing insights into the future of automation.</p>
		   		<p><strong>Error Minimization:</strong> Automation reduces human error, especially in data-heavy functions like accounting and logistics, resulting in more consistent outcomes.</p>
		    </div> 
	
	        <div className="Pillar-item-container">
		   		<h3>Employee Engagement and Workflow Efficiency:</h3>
		   		<p><strong>Engagement Boost:</strong> Automation increases employee satisfaction by reducing manual tasks and allowing workers to focus on more meaningful projects.</p>
		   		<p><strong>Overall Efficiency:</strong> Prioritizing automation at a high level leads to smoother workflows and reduced delays across departments, enhancing overall business efficiency.</p>
		    </div> 
	
		    <div className="Pillar-item-container">
		   		<h3>Addressing Labor Challenges</h3>
		   		<p><strong>Filling Staffing Gaps:</strong> Automation fills the gap in staffing shortages by handling routine tasks, allowing employees to focus on more strategic responsibilities.</p>
		   		<p><strong>Meaningful Work Opportunities:</strong> As repetitive work is automated, employees can take on more fulfilling roles, which boosts morale and reduces burnout.</p>
		    </div>
			  
			  
		  </div>
	        
	    </section>
	
		{/* end of matters section */}

	    {/* introduction section */}
		
		 <section className="Pillar-key-subcontainer" ref={keyRef} id="key">
		
		  <div className="Pillar-item-container" >
		   <h1>Key Types of Automation</h1>
		   <p>Definition: Process automation is the use of technology to perform recurring tasks or workflows, reducing manual effort.</p>
		   <ul>
		     <li className="Pillar-list" >Workflow Automation: Technology that automates a series of tasks to improve the flow and efficiency of entire processes.</li>
		     <li className="Pillar-list" >Robotic Process Automation (RPA): Technology that automates single, repetitive tasks by imitating human actions to simplify work.</li>
		   </ul>
			<p><strong>Evolution:</strong> Automation has grown from simple mechanical processes to advanced AI-driven systems that can automate both routine and complex tasks.</p>
			<p><strong>Application Across Industries:</strong> Automation is now present in sectors like healthcare, finance, and manufacturing, where repetitive tasks are abundant.</p>
	
		  </div>
		
		  <div className="Pillar-item-container">
		    <h3>Importance in Modern Business Operations</h3>
		    <p><strong>Competitiveness:</strong> In a rapidly evolving digital landscape, automation is key to staying competitive by improving productivity and lowering operational costs.</p>
			<p><strong>Increased Demand:</strong> Demand for automation from business teams has significantly increased, reflecting its growing role in streamlining operations and meeting business needs.</p>
		  </div>
	
	      <div className="Pillar-item-container">
		   <h3>Overview of What the Reader Will Learn</h3>
		   <p><strong>Key Concepts:</strong> You will learn about the benefits of automation, the technologies driving it, and its key applications across industries.</p>
		   <p><strong>Emerging Trends:</strong> The page will explore hyper-automation, AI, and the rise of low-code platforms, providing insights into the future of automation.</p>
		   <p><strong>Real-World Examples:</strong> Case studies and practical examples will illustrate how businesses have successfully implemented automation to enhance operations.</p>
		  </div>
	
	      <div className="Pillar-item-container">
		   <h3>Overview of What the Reader Will Learn</h3>
		   <p><strong>Key Concepts:</strong> You will learn about the benefits of automation, the technologies driving it, and its key applications across industries.</p>
		   <p><strong>Emerging Trends:</strong> The page will explore hyper-automation, AI, and the rise of low-code platforms, providing insights into the future of automation.</p>
		   <p><strong>Real-World Examples:</strong> Case studies and practical examples will illustrate how businesses have successfully implemented automation to enhance operations.</p>
		  </div>
	
	    </section>
	{/* end of introduction section */}
	
	{/* why is matters section */}
	
	    <section className="Pillar-matters-subcontainer" ref={strategicRef} id="strategic">
			
		  <div className="Pillar-item-container">
			<h1>Strategic Areas for Automation Implementation</h1>
		  </div>
	
		  <div className="Pillar-matters-itemscontainer">
			  
			
	        <div className="Pillar-item-container">
		   		<h3>Efficiency Improvements and Cost Reduction:</h3>
		   		<p><strong>Broad Operational Gains:</strong> Automation helps businesses achieve faster processes, reduce operational costs, and improve overall accuracy. These benefits are realized across various functions, from finance to customer service.</p>
		   		<p><strong>Resource Optimization:</strong> Automating repetitive tasks like data entry and invoicing leads to better use of resources and significant cost savings.</p>
		    </div>  
	
		    <div className="Pillar-item-container">
		   		<h3>Enhanced Productivity and Reduced Errors:</h3>
		   		<p>Boost in Output: Employees can shift their focus from mundane, repetitive tasks to higher-value work, leading to improved productivity.</p>
		   		<p><strong>Emerging Trends:</strong> The page will explore hyper-automation, AI, and the rise of low-code platforms, providing insights into the future of automation.</p>
		   		<p><strong>Error Minimization:</strong> Automation reduces human error, especially in data-heavy functions like accounting and logistics, resulting in more consistent outcomes.</p>
		    </div> 
	
	        <div className="Pillar-item-container">
		   		<h3>Employee Engagement and Workflow Efficiency:</h3>
		   		<p><strong>Engagement Boost:</strong> Automation increases employee satisfaction by reducing manual tasks and allowing workers to focus on more meaningful projects.</p>
		   		<p><strong>Overall Efficiency:</strong> Prioritizing automation at a high level leads to smoother workflows and reduced delays across departments, enhancing overall business efficiency.</p>
		    </div> 
	
		    <div className="Pillar-item-container">
		   		<h3>Addressing Labor Challenges</h3>
		   		<p><strong>Filling Staffing Gaps:</strong> Automation fills the gap in staffing shortages by handling routine tasks, allowing employees to focus on more strategic responsibilities.</p>
		   		<p><strong>Meaningful Work Opportunities:</strong> As repetitive work is automated, employees can take on more fulfilling roles, which boosts morale and reduces burnout.</p>
		    </div>
			  
			  
		  </div>
	        
	    </section>
	
		{/* end of matters section */}
	
	     </div>
		
		
		</section>
	
		<Footer />
		
	  </section>
	
	)
}

export default Automation;