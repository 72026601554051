import React from 'react';
import { NavLink } from 'react-router-dom';



import Sidebar from '../Sidebar/Sidebar.js';


import './Navbar.css';




const Navbar = () => {
	
	
	return(
		
		
	  <header className="Navbar-container">
		
		     <a href="https://www.sys.tm/welcome" className="nav homenav">
	           <img className="nav-homeIcon-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/07/Systm-nav-logo4.png" alt="" width="140" height="" />
	         </a>
		
		  <div className="Navbar-textlink-container">
		
	          <div className="Navbar-textlink-subcontainer">
		       
				  <NavLink activeclassname="active" to="/learn-about-systm" className="nav title-nav learn-nav">Learn About Sys.tm</NavLink>
				  <NavLink activeclassname="active" to="/how-might-systm-help-you" className="nav title-nav help-nav">How Might Sys.tm Help You</NavLink>
				  <NavLink activeclassname="active" to="/pricing" className="nav title-nav short-nav">Pricing</NavLink>
				  <NavLink to="/contact" className="nav title-nav short-nav">Contact and About Us</NavLink>
		          <NavLink to="/resources" className="nav title-nav short-nav">Resources</NavLink>
				  <a href="https://www.sys.tm/signin" className="nav title-nav short-nav" >Sign In</a>
		
	          </div>
		
           </div>
	  
	    <Sidebar className="Nav-sidebar" />
		
	  </header>
	
	)
}

export default Navbar;