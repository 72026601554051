import React from 'react';
import '../styles/Resources.css';

import Footer from '../Footer/Footer.js';



const articles = [
	
	
	{
	   id: '11',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm', 'Promo'],
	   title: 'Southern Computer Warehouse Partners with Epson and Digitech Systems to Offer Exclusive Document Management Bundle for a Limited Time',
	   subtitle: 'Solution Combines Epson DS Series Scanners with Sys.tm® from Digitech Systems for Enhanced Document Efficiency and Security',
       extrenal_link: './#/southern-computer/',
	   grabber_question: '',
	   excerpt: 'Southern Computer Warehouse (SCW), a leading value-added reseller of technology solutions, has announced an exclusive limited-time promotion in partnership with Epson and Digitech Systems.',
       downloads: '',
	   feature_image: 'Resources-epson'
	},
	{
	   id: '0',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm', 'Press Release', 'Robotic Process Automation'],
	   title: 'The Modular Automation Revolution',
	   subtitle: 'How Microservices and RPA Are Redefining 2025 Business Strategies',
       extrenal_link: './#/module-automation-revolution/',
	   grabber_question: '',
	   excerpt: 'As businesses adjust to rising technology expenses and unprecedented data growth, microservices architectures and Robotic Process Automation (RPA) are emerging as top priorities for those looking to maximize efficiency and savings.',
       downloads: '',
	   feature_image: 'Resources-automation'
	},
	{
	   id: '1',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm', 'Press Release', 'Generative AI', 'Automation'],
	   title: '3 Critical Technology Trends Shaping 2025',
	   subtitle: 'Generative AI, Automation, and Modular Technologies Shape Worker Experience',
       extrenal_link: './#/CriticalTrends2025/',
	   grabber_question: '',
	   excerpt: 'Microservices enable you to choose individual features that meet your specific needs. You can save money and increase your return on investment by purchasing technology that strikes a reasonable balance between price and performance.',
       downloads: '',
	   feature_image: 'Resources-micro'
	},
	{
	   id: '2',
	   tags: [ 'Microservices & Microtransactions-based Billing', 'Press Release', 'Sys.tm', 'Robotic Process Automation', 'Press Release'],
	   title: 'Digitech Systems Launches Next-Gen Information Management',
	   subtitle: 'A flexible information management platform for digital transformation and office automation.',
	   extrenal_link: './#/digitech-systems-launches-next-gen-information-management',
	   grabber_question: '',
	   excerpt: 'Today, Digitech Systems announced the availability of Sys.tm, a flexible information management platform for digital transformation and office automation that allows users to effectively manage files, share documents, and secure data.',
       downloads: '',
	   feature_image: 'Resources-sys'
	},
	{
	   id: '3',
	   tags: ['Microservices & Microtransactions-based Billing', 'Media Coverage', 'Sys.tm', 'Robotic Process Automation' ],
	   title: 'Sys.tm Brings Intelligent Information Management to SMBs',
	   subtitle: 'An innovative platform that delivers enterprise-grade content management and process automation to businesses of any size',
	   extrenal_link: 'https://usbusinessnews.com/sys-tm-brings-intelligent-information-management-to-smbs/',
	   grabber_question: '',
	   excerpt: 'Many SMBs compromise on features or security when buying technology in order to save money. Are you among them? If so, our newest product, Sys.tm offers everything you need without forcing you to pay for premium tiers or licenses explains US Business News. Check it out.',
       downloads: '',
	   feature_image: 'Resources-usb'
	},
	{
	   id: '4',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm', 'Digital Transformation', 'eBook' ],
	   title: 'Three Musts for a Successful Digital Transformation eBook',
	   subtitle: 'Feeling Stuck in Your Digital Transformation?',
       extrenal_link: 'https://info.sys.tm/#/digital-transformation',
	   grabber_question: '',
	   excerpt: 'Download the Three Musts for a Successful Digital Transformation eBook for a helpful guide to your organization’s digital transformation journey.',
       downloads: '',
	   feature_image: 'Resources-sys'
	},
	{
	   id: '5',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm', 'Media Coverage', 'Robotic Process Automation'],
	   title: 'Digitech Systems announces the availability of Sys.tm',
	   subtitle: '',
       extrenal_link: 'https://www.documentmedia.com/article-3520-Digitech-Systems-Launches-Next-Gen-Information-Management.html',
	   grabber_question: '',
	   excerpt: 'As the pace of business becomes more frantic, companies are working harder than ever to manage and secure their business information.',
       downloads: '',
	   feature_image: 'Resources-ds'
	},
	{
	   id: '6',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm' ],
	   title: 'Sys.tm Product Sheet',
	   subtitle: 'Bring Information to Life',
       extrenal_link: './#/product-sheet',
	   grabber_question: '',
	   excerpt: 'How much time and money would better information management save your business? Sys.tm provides all the power of the world’s best information management and task automation in an easy-to- setup, easy-to-use product that offers you the ability to customize available features and pay only for what you actually use.',
       downloads: 'https://www.digitechsystems.com/wp-content/uploads/2024/04/SystmProductSheet-040124.pdf',
	   feature_image: 'Resources-sys'
	},
	{
	   id: '7',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm Pricing Sheet',
	   subtitle: 'Choose The Best Plan For Your Organization',
       extrenal_link: './#/pricing-sheet/',
	   grabber_question: '',
	   excerpt: 'Download the Sys.tm pricing sheet to learn more about features included in Sys.tm Standard, Sys.tm Professional, and Sys.tm Automations (Robotic Process Automation), along with available add-ons and prebuilt integrations.',
       downloads: '',
	   feature_image: 'Resources-sys'
	},
	{
	   id: '8',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm' ],
	   title: 'Sys.tm Microservices Data Sheet',
	   subtitle: 'Ready to smart-size your technology spend?',
       extrenal_link: './#/microservices-data-sheet/',
	   grabber_question: '',
	   excerpt: 'Download the Microservices Data Sheet to learn how Digitech Systems microservices and microtransactions-based billing benefit your organization.',
       downloads: '',
	   feature_image: 'Resources-sys'
	},
	{
	   id: '9',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm' ],
	   title: 'Microservices and Microtransactions-based Billing Definitions',
	   subtitle: 'Emerging trend in tech comes with evolving terminology',
       extrenal_link: './#/microservices-definitions/',
	   grabber_question: '',
	   excerpt: 'Gain a better understanding of the emerging technology trends of microservices and microtransactions-based billing with this list of terms and descriptions.',
       downloads: '',
	   feature_image: 'Resources-sys'
	},
	{
	   id: '10',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm' ],
	   title: 'What is a Microservices Architecture? Video',
	   subtitle: 'Stop wasting money on unused software features!',
       extrenal_link: './#/architecture-video/',
	   grabber_question: '',
	   excerpt: 'Microservices enable you to choose individual features that meet your specific needs. You can save money and increase your return on investment by purchasing technology that strikes a reasonable balance between price and performance.',
       downloads: '',
	   feature_image: 'Resources-sys'
	}
	
	
]



const Resources = () => {
	
	 
	return(
		
	  <section className="Resources-main-container">
		
		<div className="Resources-promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="promo-sign-in" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025</p></a>
	    </div>
		
		<div className="Navbar-epson-subcontainer">
		  <a href="https://www.scw.com/digitech/" className="promo-sign-in" id="signup-button" aria-label="Sign up button"><p>LIMITED TIME OFFER: Exclusive Epson Document Management Bundle</p></a>
	    </div>
		
		<section className="Resources-welcome-container">
		
		  <div className="Resources-main-left">
		    <h1>Resources</h1>
		    <p>Learn more about Sys.tm and how it can benefit your business by exploring any of the resources below.</p>
		  </div>
		  <div className="Resources-main-right">
		  </div>
		</section>
		
		<section className="Resources-main-article">
		
		  <section className="Resources-article-container">
		
			{articles.map((article) => {
		
			return (
			
				<div className="Resources-article-item">
				  <div className={article.feature_image}></div>
				  <div className="Resources-article-text">
					  <h2>{article.title}</h2>
				
				<div className="Resources-article-maintag">
				  {article.tags.map((tag) => {
				
				return (
					<div className="Resources-article-tagcontainer">
					  <p>{tag}</p>
					</div>
				)
			})}
				</div>
				
					<h4>{article.subtitle}</h4>
	
				  	<p className="Resources-article-grebber"><b>{article.grabber_question}</b></p>
				    <p className="Resources-article-excerpt">{article.excerpt}</p>
					<a className="Resources-article-linkbutton" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">Learn More</a>
				  </div>
				</div>	
			)
	})}
		    </section>

		  </section>
		
		<Footer/>
			
	  </section>
	)
	
}

export default Resources;