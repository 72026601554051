import React from 'react';
import '../Ebooks/Ebook.css';

import Footer from '../Footer/Footer.js';

const BuyersChecklist = () => {
	
	return (
		
	  <section className="Ebook-main-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up for free" ><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025.</p></a>
	    </div>
		
		<div className="Ebook-main-titlecontainer">
		  <h1>Buyer’s Checklist: Choosing an Information Management System</h1>
		  <p className="Ebook-main-p">Download this Buyer’s Checklist for a list of critical features you should consider when choosing an information management system.</p>
		</div>
		
		<section className="Ebook-form-container">
		  <div className="Ebook-formContainer-left2">
		    <img className="Ebook-formContainer-img" alt="ebooks promo image" src="https://www.digitechsystems.com/wp-content/uploads/2024/12/AdobeStock_546319561.jpeg" width="" height="400"/> 
		  </div>
		
		  <div className="Ebook-formContainer-right">
		    <h4>This FREE checklist will help your organization choose the right information management system. You'll learn how to:</h4>
		
		    <div className="Ebook-listItem-container">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" alt="ebooks promo image" width="18" height="16"/>
		      <p>Improve employee productivity and efficiency.</p>
		    </div>
		
		    <div className="Ebook-listItem-container">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" alt="ebooks promo image" width="18" height="16"/>
		      <p>Automate business processes using workflows, Artificial Intelligence (AI)-aided data extraction and Robotic Process Automation (RPA).</p>
		    </div>
	
			<div className="Ebook-listItem-container">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" alt="ebooks promo image" width="18" height="16"/>
		      <p>Reduce costs associated with paper-based record keeping by making the switch to digital information management.</p>
		    </div>

		<div className="Ebook-iframe-maincontainer">
		
		  <iframe className="Ebook-iframe-container" id="" title="" width="" height="" scrolling="no" src="https://go.digitechsystems.com/Systm-Program_Systm-BuyersChecklist-LandingPage.html"></iframe>
		
		</div>
		
		  </div>
		</section>
		
		<section className="Ebook-promo-container">
		  <div className="Ebook-promo-subcontainer">
		      <h2>Try Sys.tm FREE for 30 Days</h2>
		      <p>Save Money with the Most Flexible, Affordable Product on the Market</p>
	

		      <a href="https://www.sys.tm/signup" aria-label="Try for free button" ><button className="Ebook-promo-button" >Try It Free!</button></a>

		  </div>
		</section>
		
		<section className="Products-HK-container">
		<img className="Learn-HK-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/03/hk-cutpic-color.png" width="120" height="120"/>  
		<div className="Products-HK-textcontainer">
		
		 <div className="Products-HK-item">	 
		  
		  <h5>“Sys.tm brings business information to life. Instead of languishing in boxes, critical data is securely managed and safely accessible to workers in a user-friendly, automated platform. Best of all, information doesn’t just sit waiting to be used. Rather, companies can extract data, automate tasks, comply with regulations, and much more within the Sys.tm environment. Sys.tm simplifies the ways we manage an ever-increasing amount of information.”</h5> 
	      </div>
		
	      <em><p>- HK Bain, CEO, Digitech Systems</p></em>
			 </div> 
			  
	  </section>
		
		<Footer />
		
	  </section>
	
	)
}

export default BuyersChecklist;