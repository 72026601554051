import React from 'react';
import '../styles/Posts.css';

import Footer from '../Footer/Footer.js';

const relatedArticles = [
	
	{
	   id: '2',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm® Product Sheet',
	   extrenal_link: './#/product-sheet',
	   grabber_question: 'Bring Information to Life',
	   excerpt: 'How much time and money would better information management save your business? Sys.tm provides all the power of the world’s best information management and task automation in an easy-to- setup, easy-to-use product that offers you the ability to customize available features and pay only for what you actually use.',
       downloads: '',
	   feature_image: 'Resources-usb'
	},
	{
	   id: '3',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm Microservices Data Sheet',
       extrenal_link: './#/microservices-data-sheet',
	   grabber_question: 'Ready to smart-size your technology spend?',
	   excerpt: 'Download the Microservices Data Sheet to learn how Digitech Systems microservices and microtransactions-based billing benefit your organization.',
       downloads: '',
	   feature_image: 'Resources-sys'
	}
	
]



const ResourcesPricingSheet = () => {
	 
	
	return(
		
	  <section className="Posts-main-container">
		
		<div className="Posts-promo-subcontainer">
			<a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		
		<div className="Posts-welcome-container">
			<h1>Sys.tm® Pricing Sheet</h1>
		</div>
		
	  <div className="Posts-body-container">
		
		  <h2>Choose The Best Plan For Your Organization</h2>
		  <p><b>Greenwood Village, CO – January 2, 2024 –</b>Download this form to find out about the pricing for Sys.tm. To order the services in the below schedule, please submit your request to sales@sys.tm.</p>
		<br/>
		<div className="Posts-pricing-rowcontainer">
		  
		   
		      <div className="Posts-systm-pricing"></div>
		<br/>
		   
		</div>	
		
<br/>
		<div className="Posts-intro-veritcalcontainer">
			<a href="https://www.digitechsystems.com/wp-content/uploads/2024/02/Systm-Pricing-Sheet.pdf"><button className="Posts-button">Download Now</button></a>
			<p>Download this pricing sheet for pricing</p>
		
		   <p>Technical phone support will be provided and available from 8:00 am – 6:00 pm Central Time, Monday – Friday.</p>
		
		</div>
<br/>
	  
	</div>
	
	<section className="Posts-related-section">
		<h3>Related Articles</h3>
		<div className="Posts-related-container">
			
		{relatedArticles.map((article) => {
		
			return (
			<a className="Posts-article-linkbutton" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				<div className="Posts-related-item" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				  
				  <div className="Posts-article-title">
					  <h2>{article.title}</h2>
				
				
				    <div className="Posts-article-textcontainer">
				  	  <p className="Posts-article-grebber"><b>{article.grabber_question}</b></p>
				      <p className="Posts-article-excerpt">{article.excerpt}</p>
					</div>
					
				  </div>
				</div>	
				</a>
			)
	})}
	  
	  </div>
		
	</section>
			
</section>
	)
	
}

export default ResourcesPricingSheet;